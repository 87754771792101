import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

function Home(props) {
  return (
    <Box sx={{ display: "flex" }}>
      <Typography paragraph>
        Version 0.0.1 Year 2023
      </Typography>
    </Box>
  );
}

Home.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Home;
