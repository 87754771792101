import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { contents } from "./contents";


function SocialHandle(props) {
  return (
    <Box sx={{ display: "flex",flexDirection:'row', justifyContent:'space-evenly', flexWrap:'wrap'}}>
      {contents.fb.batch[1].map(content => (
        <Box sx={{ height: '300px', width: '300px', backgroundColor:'gold', margin:'10px 0px' }}>
          <a href={content.link} target="_blank" rel="noreferrer"><img src={content.link} alt="a" width={300} height={300}/></a>
          
        </Box>

      ))}
    </Box>
  );
}

SocialHandle.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default SocialHandle;
