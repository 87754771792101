import React from "react";
import { Routes, Route } from "react-router-dom";

import Home from "./components/Home";
import Tools from "./components/Tools";
import SocialHandle from "./components/socialHandle/SocialHandle";

const Site = () => {
  return (
    <Routes>
      <Route exact path="/" element={<Home></Home>}></Route>
      <Route path="/tools" element={<Tools></Tools>}></Route>
      <Route path="/social" element={<SocialHandle/>}></Route>
    </Routes>
  );
};

export default Site;
