import React, { useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import fireB from "./config/Fire";
import { BrowserRouter } from "react-router-dom";
import Site from "./Site";
import Nav from "./Nav";

function App() {
  // useEffect(() => {
  //   fireB.init();
  // });

  return (
    <BrowserRouter>
      <Nav>
        <Site />
      </Nav>
    </BrowserRouter>
  );
}

export default App;
